import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  DatePicker,
  Radio,
  Upload,
  Select,
  InputGroup,
  Input,
  FormItem,
} from "components/ui";
import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ProfileImageUploader from "../profileImageUploader";
import { postApi, getApi } from "services/CommonService";
import { APIS } from "constants/api.constant";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import { getUserListing } from "services/commonApi";

const MixComponents = ({
  field,
  setFieldValue,
  values,
  errors,
  touched,
  setFieldTouched,
  isLabel = true,
  index,
  id,
}) => {
  const [lookUpOptions, setLookOptions] = useState([]);
  const [allStatesDistricts, setAllStatesDistricts] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useLayoutEffect(() => {
    if (field.component === "select" && field?.isLookUp) {
      postApi(APIS.COMMON_LOOK_UP, {
        enSoureLookupType: field.enSoureLookupType,
      }).then((res) => {
        let data = res?.dto?.[field.name]?.map((obj, i, arr) => {
          return { label: obj?.[field.keyName], value: obj };
        });
        setAllStatesDistricts(data);
        setLookOptions(data);
      });
    } else if (field.component === "select" && field?.delearOptions) {
      getUserListing({
        pageIndex: 0,
        pageSize: 10,
        totalPages: 0,
        filterText: "",
        userType: 109,
      }).then((res) => {
        setLookOptions(
          res?.dto?.map((obj, i, arr) => {
            return { label: obj?.name, value: obj };
          })
        );
      });
      setLookOptions([{ label: "Check 1", value: "Check 1" }]);
    } else {
      setLookOptions(field.options);
    }
  }, []);

  ///// GET DISTRICTS FROM SELECTED STATED//////

  useEffect(() => {
    if (
      field.component === "select" &&
      field?.isLookUp &&
      field?.dependentKey === "states"
    ) {
      let statesIds = values?.states?.map((obj, i, arr) => obj?.value?.id);
      let districtsArray = allStatesDistricts.filter((obj, i, arr) =>
        statesIds?.includes(obj?.value?.stateId)
      );
      setFieldValue(
        field.name,
        values?.[field.name]?.filter((obj, i, arr) =>
          statesIds?.includes(obj?.value?.stateId)
        )
      );
      setLookOptions(districtsArray);
    }
    if (
      field?.component === "select" &&
      field?.isLookUp &&
      field?.dependentKey === "dealers"
    ) {
      if (values?.[field?.dependentKey]?.length) {
        let dealersStatesId = values?.[field?.dependentKey].map(
          (obj, i, arr) => {
            return obj.value.states.map((states, index, ar) => {
              return id;
            });
          }
        );
        dealersStatesId = [6];
        setLookOptions(
          allStatesDistricts?.filter((obj, i, arr) =>
            dealersStatesId?.includes(obj?.value?.stateId)
          )
        );
      } else {
        setLookOptions([]);
      }
    }
  }, [values?.[field?.dependentKey], refresh]);

  const loadSelectOption = (inputValue, resolve) => {
    getApi(APIS.GET_CODE, { type: 2, search: inputValue }).then((result) => {
      const filter = result?.data?.data.map((item) => {
        let option = {
          label: item?.name,
          value: item?.id,
        };
        return option;
      });
      resolve(filter);
    });
  };
  const loadoptions = debounce(loadSelectOption, 300);

  const LabelSection = ({ field, isLabel }) => {
    return (
      <div>
        {isLabel ? (
          <div>
            {field?.label}
            {field?.isRequired && <span className="text-red-600">*</span>}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <>
      {field?.isBasic && (
        <FormItem
          key={index}
          label={<LabelSection field={field} isLabel={isLabel} />}
          invalid={errors?.[field?.name] && touched?.[field?.name]}
          errorMessage={
            <div className="italic text-sm">{errors?.[field?.name]}</div>
          }
        >
          <Field
            key={index}
            textArea={field?.textArea ? true : false}
            type={field?.type}
            disabled={id && field.name === "employeeCode"}
            autoComplete="off"
            name={field?.name}
            placeholder={field?.placeholder}
            component={field?.component}
            {...field}
          />
        </FormItem>
      )}

      {field.component === "date-time-picker" && (
        <div className="font-semibold">
          <LabelSection isLabel={isLabel} field={field} />
          <DatePicker.DateTimepicker
            onBlur={() => {
              setFieldTouched(field?.name);
            }}
            key={field.name}
            inputFormat="MMM, DD YYYY"
            clearable={false}
            className={isLabel ? "mt-2" : "mt-0"}
            name={field.name}
            defaultValue={new Date(values?.[field.name])}
            value={new Date(values?.[field.name])}
            onChange={(date) => {
              setFieldValue(field.name, date);
            }}
            {...field}
          />
          {errors?.[field?.name] && touched?.[field?.name] ? (
            <div className="font-normal	italic text-sm text-[#e31e24]">
              {errors?.[field?.name]}
            </div>
          ) : (
            <div className="invisible	text-sm">Error</div>
          )}
        </div>
      )}
      {field.component === "datepicker" && (
        <div className="font-semibold">
          <LabelSection isLabel={isLabel} field={field} />
          <DatePicker
            onBlur={() => {
              setFieldTouched(field?.name);
            }}
            key={field.name}
            inputFormat="MMM, DD YYYY"
            clearable={false}
            className={isLabel ? "mt-2" : "mt-0"}
            name={field.name}
            defaultValue={new Date(values?.[field.name])}
            value={new Date(values?.[field.name])}
            onChange={(date) => {
              setFieldValue(field.name, date);
            }}
            {...field}
          />
          {errors?.[field?.name] && touched?.[field?.name] ? (
            <div className="font-normal	italic text-sm text-[#e31e24]">
              {errors?.[field?.name]}
            </div>
          ) : (
            <div className="invisible	text-sm">Error</div>
          )}
        </div>
      )}

      {field.component === "radio" && (
        <div className="w-full flex item-center font-semibold">
          <LabelSection isLabel={isLabel} field={field} />
          {field?.options?.map((g, i) => {
            return (
              <Radio
                key={i}
                className="mr-4 ml-3 mb-5 md:mt-3 "
                name="simpleRadioExample1"
                value={g.value}
                onChange={(date) => setFieldValue(field.name, date)}
                checked={g.value === values?.[field.name] ? true : false}
              >
                {g.label}
              </Radio>
            );
          })}
        </div>
      )}

      {field.component === "phoneNumber" && (
        <div>
          <div className="font-semibold">
            <LabelSection isLabel={isLabel} field={field} />
          </div>
          <PhoneInput
            inputStyle={{
              width: "100%",
              padding: "11px 14px 11px 60px",
              maxHeight: "44px",
            }}
            enableSearch={true}
            country={"in"}
            className={isLabel ? "mt-2" : "mt-0"}
            countryCodeEditable
            onBlur={() => {
              setFieldTouched(field?.name);
            }}
            value={`${values?.[field.name]}`}
            onChange={(phone, country) => {
              setFieldValue(field.name, phone);
              setFieldValue(field.countryCode, country?.dialCode);
            }}
          />
          {errors?.[field?.name] && touched?.[field?.name] ? (
            <div className="font-normal	italic text-sm text-[#e31e24]">
              {errors?.[field?.name]}
            </div>
          ) : (
            <div className="invisible	text-sm">Error</div>
          )}
        </div>
      )}

      {field.component === "upload" &&
        (field.isProfileUpload ? (
          <ProfileImageUploader setFieldValue={setFieldValue} field={field} />
        ) : (
          <InputGroup className="mb-4">
            <Input value={values[field.name]} />
            <Upload
              uploadLimit={1}
              showList={false}
              accept={["image/jpeg", "image/png"]}
              name={field.name}
              onChange={(imgs) => {
                let payload = new FormData();

                for (let i = 0; i < imgs?.length; i++) {
                  payload.append("image", imgs[i]);
                }

                postApi(APIS.UPLOAD_IMAGE, payload).then((res) => {
                  let files = [];

                  if (res?.data?.length) {
                    files = [...files, ...res?.data];
                  } else {
                    files = [...files, res?.data];
                  }

                  setFieldValue(field.name, files[0]?.original);
                });
              }}
            ></Upload>
          </InputGroup>
        ))}
      {field.component === "asyncSelect" && (
        <div className="font-semibold">
          <LabelSection isLabel={isLabel} field={field} />
          <Field
            isMulti={field?.isMulti ? true : false}
            className={isLabel ? "mt-2" : "mt-0"}
            component={Select}
            autoComplete="off"
            placeholder={field.placeholder}
            defaultOptions
            cacheOptions
            loadOptions={loadoptions}
            componentAs={AsyncSelect}
            onChange={(event) => {
              setFieldValue(field.name, event);
            }}
            name={field.name}
            value={values?.[field.name]}
          />
        </div>
      )}
      {field.component === "select" && (
        <div className={isLabel ? "mb-3" : "mb-0"}>
          <div className="font-semibold">
            <LabelSection isLabel={isLabel} field={field} />
          </div>
          <Field
            isMulti={field?.isMulti ? true : false}
            component={Select}
            autoComplete="off"
            onBlur={() => {
              setFieldTouched(field?.name);
            }}
            onFocus={() => {
              setRefresh(!refresh);
            }}
            className={isLabel ? "mt-2" : "mt-0"}
            placeholder={field.placeholder}
            options={lookUpOptions}
            onChange={(event) => {
              if (field?.isMulti) {
                setFieldValue(field.name, event);
              } else {
                setFieldValue(field.name, [event]);
              }
            }}
            name={field.name}
            value={field.options.find(
              (item) => item?.value === values?.[field.name]
            )}
          />
          {errors?.[field?.name] && touched?.[field?.name] ? (
            <div className="font-normal	italic text-sm text-[#e31e24]">
              {errors?.[field?.name]}
            </div>
          ) : (
            <div className="invisible	text-sm">Error</div>
          )}
        </div>
      )}
    </>
  );
};
export default MixComponents;
