import useThemeClass from "utils/hooks/useThemeClass";
import { Tooltip } from "components/ui";

const ActionColumn = ({ row, onActionHandle, actionsMenu }) => {
  const { textTheme } = useThemeClass();

  return (
    <div className="flex items-center">
      {actionsMenu?.map((item, i) => {
        return (
          <Tooltip
            title={item?.toolTip}
            visible={item?.toolTip ? true : false}
            key={i}
          >
            {item?.isImage ? (
              <img
                src={item.label}
                alt="view Icon"
                style={{ minWidth: "20px" }}
                // h-5 justify-center ml-5 text-blue-400
                className="cursor-pointer"
                onClick={(e) => onActionHandle(item.key, row)}
              />
            ) : (
              <span
                className={`${textTheme} cursor-pointer select-none font-semibold ml-2 capitalize`}
                onClick={(e) => onActionHandle(item.key, row)}
              >
                {item.label}
              </span>
            )}
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ActionColumn;
