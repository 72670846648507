import React from "react";
import { Avatar } from "components/ui";
import { NO_DATA_FOUND } from "constants/app.constant";

const NameColumn = ({ row, fieldName }) => {
  return (
    <div className="flex items-center">
      {row?.[fieldName] !== '' && row?.[fieldName] !== undefined && row?.[fieldName] !== null && row?.[fieldName] ? (
        <Avatar
          size={28}
          shape="circle"
          className="mr-2"
          // src={`${appConfig.imageBaseUrl}${row?.[fieldName]}`}
          src={row?.[fieldName]}
        />
      ) : (
        <Avatar size={28} shape="circle" className="mr-2">
          {row?.name?.[0]?.toUpperCase()}
        </Avatar>
      )}

      <div>
        {row?.name ? (
          <div className="font-semibold capitalize" style={{ minWidth: "100px" }}>{`${row?.name}`}</div>
        ) : (
          NO_DATA_FOUND
        )}
        {row?.employeeCode ? <div className="text-red-600 font-semibold	">{row?.userType === 109 ? row?.dealerCode : row?.employeeCode}</div> : NO_DATA_FOUND}
      </div>
    </div>
  );
};

export default NameColumn;

