import React from "react";

const NoDataFound = () => {
  return (
    <div className="flex justify-center items-center h-1 6 font-bold">
      No Data Found
    </div>
  );
};
export default NoDataFound;
