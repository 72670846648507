export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const SML_AUTH_HEADER_KEY = "Basic c21saWNybTpTbWxpQENSTTI0";

export const APIS = {
  AUTH: {
    LOGIN: "User/Login",
  },
  LEADS: {
    GET_LEADS: "Lead/GetAllLeads_V2",
  },
  GENERATE_LEAD: "Lead/GenerateLead",
  USERS: {
    CREATE_USER: "User/CreateUser",
    UPDATE_USER: "User/UpdateUser",
  },
  EXPORT_LEAD : "/ExcelExporter/ExportLeadToExcel",
  DEALER_SUMMARY_REPORT : 'Lead/GetSAPOrderDetailData',
  PROJECTION_SUMMARY_REPORT : 'Lead/GetProjectionSummaryDetailData',
  STATE_PROJECTION_SUMMARY_EXPORT_LEAD : "/ExcelExporter/ExportProjectionSummaryDetailDataToExcel",
  LIST_USERS: "User/GetUsers",
  CREATE_USER: "User/CreateUser",
  COMMON_LOOK_UP: "Common/Lookup",
  USER_BY_ID: "User/GetUserById",
  UPDATE_USER: "User/UpdateUser",
  GET_VEHICLES_MODELS: "VehicleModel/GetVehicleModels",
  GET_VEHICLE_MODELS_BY_ID: "VehicleModel/GetVehicleModelById",
  UPDATE_VEHICLE_MODAL: "VehicleModel/UpdateVehicleModel",
  CREATE_VEHICLE_MODAL: "VehicleModel/CreateVehicleModel",
  CREATE_UPDATE_CUSTOMER_CATEGORY: "Common/CreateOrUpdateCustomerCategory",
  CREATE_UPDATE_FOLLOWUP_TYPE: "Common/CreateOrUpdateFollowUpType",
  CREATE_UPDATE_VISIT_PURPOSE: "Common/CreateOrUpdateVisitPurpose",
  ENQUIRY: {
    GET_ENQUIRY: "VehicleModel/GetEnquires",
    ADD_EDIT_ENQUIRY: "VehicleModel/CreateOrUpdateEnquire",
  },
  COMPETITORS: {
    GET_COMPETITORS: "VehicleModel/GetCompetitors",
    ADD_EDIT_COMPETITOR: "VehicleModel/CreateOrUpdateCompetitor",
  },
  STATES: {
    ADD_EDIT_STATE: "Common/CreateOrUpdateState",
  },
  DISTRICTS: {
    ADD_EDIT_DISTRICT: "Common/CreateOrUpdateDistrict",
  },
  DEALER: {
    GET_DEALER_STOCK: "/SmlApi/GetDealerStock",
  },
  DASHBOARD: {
    GET_DASHBOARD_REPORTS: "/Lead/GetPanelDashboardReports",
  },

  CHANGE_PASSWORD : "/crmapi/api/UserLogin/UserChangePassword"

};
