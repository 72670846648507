import { APIS } from "constants/api.constant";
import { postApi, getApi } from "./CommonService";
import appConfig from "configs/app.config";
import { toast , Notification} from "components/ui";
import moment from "moment";

export const getUserListing = async ({
  pageIndex,
  pageSize,
  totalPages,
  filterText,
  userType,
  otherObj,
}) => {
  return await postApi(APIS.LIST_USERS, {
    pageIndex,
    pageSize,
    totalPages,
    filterText,
    userType: [userType],
    ...otherObj,
  }).then((res) => {
    return res;
  });
};

export const addUpdateuser = async (data) => {
  return await postApi(APIS.UPDATE_USER, { ...data }).then((res) => {
    return res;
  });
};

export const getUserById = async ({ id: id }) => {
  return await getApi(APIS.USER_BY_ID, { id: Number(id) }).then((res) => res);
};

export const getCommonAPIListing = async ({
  api,
  pageIndex,
  pageSize,
  totalPages,
  filterText,
  otherObj,
}) => {
  return await postApi(api, {
    pageIndex,
    pageSize,
    totalPages,
    filterText,
    ...otherObj,
  }).then((res) => {
    return res;
  });
};


export const excelExportCommon = async (apiName, token, bodyData, fileName, setExportLoading) => {

  setExportLoading(true)
  fetch(appConfig.apiBaseUrl + apiName, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    body: JSON.stringify(bodyData),
  })
    .then(response => {
      if (!response.ok) {
        toast.push(<Notification type="error">`HTTP error! Status: ${response.status}`</Notification>);
      }
      return response.blob(); // Get the file as a Blob
    })
    .then(blob => {
      // Create a temporary anchor element
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}_${moment().format('DD-MMM-YYYY')}.xlsx`; // File name to download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toast.push(<Notification type="success">File downloaded successfully</Notification>);
    })
    .catch(error => {
      toast.push(<Notification type="error">`Error downloading Excel file: {error}`</Notification>);
    })
    .finally(() => {
      setExportLoading(false)
    })
}