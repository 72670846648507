import { FormItem } from "components/ui";
import { Field } from "formik";
import React from "react";

const FormBasicFields = ({ touched, errors, field, index, isLabel = true }) => {
  return (
    <>
      <FormItem
        key={index}
        label={isLabel ? field?.label : ''}
        invalid={errors?.[field?.name] && touched?.[field?.name]}
        errorMessage={
          <div className="italic text-sm">{errors?.[field?.name]}</div>
        }
      >
        <Field
          key={index}
          textArea={field?.textArea ? true : false}
          type={field?.type}
          autoComplete="off"
          name={field?.name}
          placeholder={field?.placeholder}
          component={field?.component}
          {...field}
        />
      </FormItem>
    </>
  );
};

export default FormBasicFields;
