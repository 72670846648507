import React from 'react'
import TableSearchBar from '../TableSearchBar';
import { DatePicker, Button } from 'components/ui';
import { GrPowerReset } from "react-icons/gr";
import { CiFilter } from "react-icons/ci";


const FilterBar = ({
    search,
    FilterMenu,
    setSearch,
    setFilterValue,
    filterValue,
    onFilterClick,
    motionFilters
}) => {

    return (
        <div className="flex flex-wrap w-full items-center justify-between">
            <div className="flex w-full gap-4 sm:w-8/12 md:w-8/12 lg:w-8/12 xl:w-8/12 ">
                {
                    FilterMenu?.isSearch &&
                    <div className="flex w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                        <TableSearchBar
                            search={search}
                            placeholder={FilterMenu?.isSearch?.placeholder}
                            onChange={(query) => setSearch(query)}
                            SearchBarInfo={FilterMenu?.isSearch?.toolTipText}
                        />
                    </div>
                }
                {FilterMenu?.isCalendarFilter &&
                    <div className="flex w-full 'sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 ">
                        <DatePicker.DatePickerRange
                            size="sm"
                            dateViewCount={2}
                            value={[
                                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey1],
                                filterValue?.[FilterMenu?.isCalendarFilter?.filterKey2],
                            ]}
                            placeholder={FilterMenu?.isCalendarFilter?.placeholder}
                            onChange={(dateArray) => {
                                setFilterValue({
                                    ...filterValue,
                                    [FilterMenu?.isCalendarFilter?.filterKey1]: dateArray[0],
                                    [FilterMenu?.isCalendarFilter?.filterKey2]: dateArray[1],
                                });
                            }}
                        />
                    </div>
                }
            </div >
            <div className="flex w-full sm:w-3/12  md:w-3/12 lg:w-3/12 xl:w-3/12 justify-end">
                {
                    FilterMenu?.isFilterButton &&
                    <Button
                        size="sm"
                        className={`mr-2 ${motionFilters && 'text-red-500'}`}
                        onClick={onFilterClick}
                        icon={<CiFilter color={motionFilters && 'red'} />}

                    >
                        Filter
                    </Button>
                }
                {
                    FilterMenu?.isResetButton &&
                    <Button
                        size="sm"
                        onClick={() => {
                            setFilterValue(FilterMenu?.isResetButton?.filterKey);
                            setSearch('')
                        }}
                        icon={<GrPowerReset />}
                    >
                        Reset
                    </Button>
                }
            </div>
        </div >
    )
}
export default FilterBar
