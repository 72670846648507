import React from "react";

const InfoColumn = ({ column1, column2, column3 }) => {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {column1 !== undefined && column1 !== null && column1 !== "" ? (
        <div className="capitalize font-bold">{column1}</div>
      ) : (
        <div className="capitalize font-bold">No Name</div>
      )}
      {column2 !== undefined && column2 !== null && column2 !== "" && (
        <div className="font-bold text-slate-400">{column2}</div>
      )}
      {column3 !== undefined && column3 !== null && column3 !== "" && (
        <div className="font-bold text-slate-400">{column3}</div>
      )}
    </div>
  );
};
export default InfoColumn;
