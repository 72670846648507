export const USER_TYPES = {
  DEALER: 109,
  SALES_EXECUTIVE: 105,
  ZONAL_BUSINESS_HEAD: 302,
  TERRIROTY_SALES_MANAGER: 303,
  STATE_BUSINESS_HEAD: 312,
  MARKETING_HEAD: 301,
  SEGMENT_HEAD: 315,
  DEALER_MARKETING_MANAGER: 108,
  INTERNAL_AUDIT: 3001,
};
