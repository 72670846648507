import React, { useEffect, useState } from "react";
import { IMAGES } from "constants/app.constant";

const GREETING_VALUES = [
  {
    title: "🎈 Happy New Year 🎈",
    date: "28-11",
    leftIcon: IMAGES.FIREWORKS,
    rightIcon: IMAGES.FIREWORKS,
  },
  {
    title: "Happy Lohri",
    date: "13-1",
    leftIcon: IMAGES.LOHRI,
    rightIcon: IMAGES.LOHRI,
  },
  { title: "Happy Diwali", date: "3", theme: "" },
];
const CurrentDate = `${new Date().getDate()}-${new Date().getMonth() + 1}`;


const GreetTransition = ({ }) => {
  const [activeObj, setActiveObj] = useState(
    GREETING_VALUES.find((obj, i, arr) => obj.date === CurrentDate)
  );
  const [hideText, setHideText] = useState("opacity-0");

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setHideText("opacity-100");
      const timer2 = setTimeout(() => {
        setHideText("opacity-0");
      }, 4500);
      return () => clearTimeout(timer2);
    }, 1000);

    return () => clearTimeout(timer1);
  }, []);

  return (
    <>
      {activeObj && (
        <div
          className={`transition delay-150  ${hideText}
              } duration-700 text-white font-semibold text-2xl font-mono flex items-center`}
        >
          <img
            src={activeObj?.leftIcon}
            style={{ height: "70px", width: "80px" }}
            className="mr-2"
            alt="festive1"
          />
          {activeObj?.title}
          <img
            src={activeObj?.rightIcon}
            style={{ height: "70px", width: "80px" }}
            className="ml-2"
            alt="festive2"
          />
        </div>
      )}
    </>
  );
};
export default GreetTransition;
