import { createContext, useContext } from "react";
import { SIZES } from "../utils/constant";
import { THEME_ENUM } from "constants/theme.constant";

export const defaultConfig = {
  themeColor: "red",
  direction: THEME_ENUM.DIR_LTR,
  mode: THEME_ENUM.MODE_LIGHT,
  locale: "en",
  primaryColorLevel: 600,
  cardBordered: false,
  panelExpand: false,
  controlSize: SIZES.MD,
  navMode: THEME_ENUM.NAV_MODE_LIGHT,
  layout: {
    type: THEME_ENUM.LAYOUT_TYPE_DECKED,
    sideNavCollapse: false,
  },
};

export const ConfigContext = createContext(defaultConfig);

const ConfigProvider = ConfigContext.Provider;

export const ConfigConsumer = ConfigContext.Consumer;

export function useConfig() {
  return useContext(ConfigContext);
}

export default ConfigProvider;
