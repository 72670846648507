import React from "react";
import { motion } from "framer-motion";
import { Select, DatePicker } from "components/ui";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";

const MotionFilters = ({
  motionFilters,
  FilterMenu,
  setFilterValue,
  filterValue,
}) => {
  const loadStaysOption = (inputValue, resolve) => {
    // getApi(asyncApiType === 1 ? APIS.GET_USERS : APIS.GET_CATEGORIES, {
    //   search: inputValue,
    // }).then((result) => {
    //   resolve(result?.data?.data);
    // });
  };
  const loadStays = debounce(loadStaysOption, 300);

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: !motionFilters ? 0 : 1,
        height: !motionFilters ? 0 : "auto",
      }}
      transition={{ duration: 0.15 }}
    >
      <div className="mt-4 md:flex gap-4 w-full">
        {FilterMenu?.isFilterButton?.filters?.map((filter, i) => {
          return (
            <div key={i} className={`w-full xs:mb-4 mt-4 sm:mb-4 md:w-1/5`}>
              {!!filter?.label && <label>{filter?.label}</label>}
              {filter.component === "select" && (
                <Select
                  autoComplete="off"
                  size="sm"
                  className={filter.className}
                  placeholder={filter.placeholder}
                  options={filter.options}
                  value={filterValue[filter?.filterKey]}
                  onChange={(selectedValue) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: selectedValue,
                    });
                  }}
                />
              )}
              {filter.component === "async-select" && (
                <Select
                  autoComplete="off"
                  placeholder={filter.placeholder}
                  defaultOptions
                  cacheOptions
                  size="sm"
                  style={filter.style}
                  //   className={filter.className}
                  value={filterValue?.[filter?.filterKey]}
                  loadOptions={loadStays}
                  componentAs={AsyncSelect}
                  getOptionLabel={(v) =>
                    `${v?.[filter.label]} ${
                      filter?.label1 ? v?.[filter.label1] : ""
                    }`
                  }
                  getOptionValue={(v) => v?.id}
                  onChange={(event) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey]: event,
                    });
                  }}
                />
              )}
              {filter.component === "date-range-picker" && (
                <DatePicker.DatePickerRange
                  size="sm"
                  dateViewCount={2}
                  value={[
                    filterValue?.[filter?.filterKey1],
                    filterValue?.[filter?.filterKey2],
                  ]}
                  placeholder={filter?.placeholder}
                  onChange={(dateArray) => {
                    setFilterValue({
                      ...filterValue,
                      [filter?.filterKey1]: dateArray[0],
                      [filter?.filterKey2]: dateArray[1],
                    });
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </motion.div>
  );
};

export default MotionFilters;
