import React from "react";
import { Button } from "components/ui";
import { AiOutlineSave, AiOutlineCloseCircle } from "react-icons/ai";

const FormActionButtons = ({ onCancelClick, isSubmitting, buttonLabel }) => {
  return (
    <div className="flex mb-3 justify-end ">
      <Button
        size="sm"
        className="ltr:mr-3 rtl:ml-3"
        onClick={onCancelClick}
        icon={<AiOutlineCloseCircle />}
        type="button"
      >
        Cancel
      </Button>
      <Button
        size="sm"
        variant="solid"
        loading={isSubmitting}
        icon={<AiOutlineSave />}
        type="submit"
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default FormActionButtons;
