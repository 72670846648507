export const ADMIN = "admin";
export const USER = "user";

export const ROLES = {
  SUPER_ADMIN: 1, 
  CONFIG_ADMIN: 305,
  MARKETING_HEAD: 301,
  ZONAL_BUSINESS_HEAD: 302,
  STATE_BUSINESS_HEAD: 312,
  SEGMENT_HEAD: 315,
  TERRITORY_SALES_MANAGER: 303,
  DEALER_MARKETTING_MANAGER: 108,
  SALES_EXECUTIVE: 105,
  DEALER_PANEL: 109,
  VERIFICATION: 5002,
  INTERNAL_AUDIT : 3001
};
