import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    states: null,
    districts: null,
    fetch: false,
}

export const statesDistricts = createSlice({
    name: 'base/states-districts',
    initialState,
    reducers: {
        setStateDistrict: (state, action) => {
            state.states = action.payload.states
            state.districts = action.payload.districts
            state.fetch = action.payload.fetch
        }
    },
})

export const { setStateDistrict } = statesDistricts.actions

export default statesDistricts.reducer