import React from "react";
import { Checkbox } from "components/ui";
import {
  TABLE_ACTION_KEYS,
  ACTIVE_INACTIVE_STATUS,
} from "constants/app.constant";

const ActiveInActiveCheckbox = ({ dataObj, onActionHandle }) => {
  const activeValue = ACTIVE_INACTIVE_STATUS.find(
    (item, i) => item.identifier === dataObj?.statuses?.[0]?.id
  );

  return (
    <>
      <Checkbox
        checked={activeValue?.value}
        onChange={(e) => onActionHandle(TABLE_ACTION_KEYS.STATUS, dataObj)}
      />
    </>
  );
};
export default ActiveInActiveCheckbox;
