import React, { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";

const NO_IMAGE = "/img/avatars/noImage.webp";

const ProfileImageUploader = ({ setFieldValue, fieldName, errors, values }) => {
  const [slectedImage, setSelectedImage] = useState(null);



  useEffect(() => {
    if (values?.[fieldName]) { setSelectedImage(values?.[fieldName]) }
  }, [values])

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setFieldValue(fieldName, reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <label htmlFor="imageUpload" className="relative inline-block mt-2 mb-5">
        <input
          type="file"
          id="imageUpload"
          className="hidden"
          accept="image/*"
          onChange={handleImageChange}
        />
        <div className="w-32 h-32 rounded-full overflow-hidden mx-auto bg-gray-200 flex items-center justify-center">
          <img
            src={slectedImage ? slectedImage : NO_IMAGE}
            alt="Profile"
            className="object-cover w-full h-full"
          />
          <div className="absolute bottom-0 w-full bg-opacity-50 p-2 flex justify-end mt-2">
            <div className="bg-gray-300 rounded-full h-7 w-7 flex justify-center items-center">
              <FaCamera className="text-white text-xl cursor-pointer" />
            </div>
          </div>
        </div>
      </label>

      {/* {errors ? (
        <div className="font-normal	italic text-sm text-[#e31e24] mb-5">
          {errors}
        </div>
      ) : (
        <div className="invisible	text-sm mb-5">Error</div>
      )} */}
    </>
  );
};

export default ProfileImageUploader;
