import React from "react";
import { Dialog, Button } from "components/ui";

const ConfirmationDialog = ({
  openDialog,
  heading,
  description,
  buttonLabel,
  onConfirmHandle,
  buttonLabelLoading,
  loading = false,
  selectedData,
  onCloseDialog,
}) => {
  return (
    <Dialog
      isOpen={openDialog}
      onClose={onCloseDialog}
      style={{
        content: {
          marginTop: 250,
        },
      }}
    >
      <h4>{heading}</h4>
      <p className="pl-2 pt-6">{description}</p>
      <div className="mt-4 flex justify-end">
        <Button
          onClick={onCloseDialog}
          className="mr-2 capitialize"
          variant="default"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => onConfirmHandle(e, selectedData)}
          variant="solid"
          className="capitialize"
          loading={loading}
        >
          {loading ? buttonLabelLoading : buttonLabel}
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmationDialog;
