
import { COMMON_DATE_FORMAT } from "constants/app.constant";
import { Tag } from "components/ui";
import { HiOutlinePlusCircle } from "react-icons/hi";
import {
  TABLE_ACTION_KEYS,
  USER_NAME_BY_USER_TYPE,
} from "constants/app.constant";
import { getState } from "store";
import { PERMISSIONS } from "constants/permission.contant";
import moment from "moment";
import { postApi } from "services/CommonService";
import { APIS } from "constants/api.constant";
import { LOOK_UP_TYPES } from "constants/lookupTypes";
import { USER_TYPES } from "constants/userTypes";
import { use } from "i18next";

const USER_STATUS = [
  { id: 2, name: "Active" },
  { id: 3, name: "Deactive" },
];

export const generateDeviceId = () => {
  let deviceId = localStorage.getItem("device_id");
  if (!deviceId) {
    deviceId = `userID_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  }
  localStorage.setItem("device_id", deviceId);
  return deviceId;
};

export const arrayToString = (fieldName) => {
  return (
    <>
      {[fieldName]?.length > 0
        ? [fieldName].map((str, i, arr) => {
            return (
              <div className="capitalize">{`${str} ${
                i !== [fieldName]?.length - 1 ? "," : ""
              }`}</div>
            );
          })
        : "-"}
    </>
  );
};

export const ValidateData = (data) => {
  if (data === null || data === undefined || data === "" || data.length === 0) {
    return false;
  }
  return true;
};

export const getFormatedDate = (date, formatter = COMMON_DATE_FORMAT) => {
  const dateObject = moment.utc(date).local()
  const formattedDate = dateObject.format(formatter);
  return formattedDate;
};


export const getTagLabelFromObject = (value, object) => {
  const dataObj = object.find((obj) => obj?.value === value);
  return (
    <Tag
      className={`bg-${dataObj?.color}-100 text-${dataObj?.color}-600 dark:bg-${dataObj?.color}-500/20 dark:text-${dataObj?.color}-100 border-0 rounded`}
    >
      {dataObj.label}
    </Tag>
  );
};

export const chnageUserStatus = (currentValue) => {
  let statusObj = USER_STATUS.find((obj, i, arr) => obj.id !== currentValue);
  return {
    status: { statusId: statusObj.id },
    statuses: [statusObj],
  };
};

export const getAddButtonObj = ({ heading }) => {
  return [
    {
      label: heading,
      key: TABLE_ACTION_KEYS.ADD,
      icon: <HiOutlinePlusCircle />,
    },
  ];
};

export const getLabelFromObject = (array, value) => {
  let obj = array.find((obj, i, arr) => obj?.value === value);
  if (obj === undefined) {
    return "NA";
  } else {
    return obj.label;
  }
};

export const getFirstDayOfCurrMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getLastDayOfCurrMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
};

export const getUserStatus = (value) => {
  return USER_STATUS.find((obj, i) => obj.id === value);
};

export const getStateDistrictFromArray = (array, key) => {
  return array.map((obj, i, arr) => {
    return obj?.[key];
  });
};

export const getDealerFromArray = (array, keys) => {
  return array.map((obj, i, arr) => {
    return `${obj?.[keys[0]]} (${obj?.[keys[1]]})`;
  });
};

export const changeStateId = (states) => {
  return states?.map((obj, i, arr) => {
    return {
      name: obj?.value?.name,
      countryId: obj?.value?.countryId,
      stateId: obj?.value?.id,
    };
  });
};

export const hasPermission = (action, pageIdentifier) => {
  let currentUserType = getState().auth?.user?.userType;
  const permissionnObj = PERMISSIONS.find(
    (obj, i, arr) => obj.pageIdentifier === pageIdentifier
  );
  return permissionnObj?.[action]?.includes(currentUserType);
};

export const getActiveUser = () => {
  let activeUserobj = USER_NAME_BY_USER_TYPE.find(
    (obj, i, arr) => obj.value === getState().auth?.user?.userType
  );
  return activeUserobj.label;
};

export const formatAmount = (amount) => {
  const nf = new Intl.NumberFormat("en-IN");
  return `₹ ${nf.format(amount)}`;
};

export const loadStateOptions = function(currentUser){
  return new Promise((resolve, reject) => {
  if(currentUser && currentUser.states && currentUser.states.length){
    let allOptions = currentUser.states?.map((data) => ({
      label: data?.stateName,
      value: data,
    }));
    resolve(allOptions);
  }
  else {
    postApi(APIS.COMMON_LOOK_UP, {
      enSoureLookupType: LOOK_UP_TYPES.STATE,
    }).then((res) => {
      if (res?.dto) {
        let allOptions = res?.dto?.states?.map((data) => ({
          label: data?.stateName,
          value: data,
        }));
        resolve(allOptions);
      }
    });
  }
  })
};

export const loadDealerOptions = (inputValue, callback) => {
  postApi(APIS.LIST_USERS, {
    userType: [USER_TYPES.DEALER],
    pageIndex: 0,
    pageSize: 300,
    totalPages: 0,
    filterText: inputValue || "",
  }).then((res) => {
    if (res?.dto) {
      if (res?.dto?.length) {
        callback(
          res?.dto?.map((user) => ({ label: `${user?.name} - ${user?.dealerCode}`, value: user }))
        );
      }
    }
  });
};

export const loadSalesExecutiveOptions = (inputValue, callback) => {
  postApi(APIS.LIST_USERS, {
    userType: [USER_TYPES.SALES_EXECUTIVE],
    pageIndex: 0,
    pageSize: 300,
    totalPages: 0,
    filterText: inputValue || "",
  }).then((res) => {
    if (res?.dto) {
      if (res?.dto?.length) {
        callback(
          res?.dto?.map((user) => ({ label: `${user?.name} - ${user?.employeeCode}`, value: user }))
        );
      }
    }
  });
};

export const loadModelsOptions = (inputValue, callback) => {
  postApi(APIS.GET_VEHICLES_MODELS, {
    pageIndex: 0,
    pageSize: 100,
    totalPages: 0,
    filterText: inputValue || "",
  }).then((res) => {
    if (res?.dto) {
      if (res?.dto?.length) {
        callback(
          res?.dto?.map((user) => ({ label: `${user?.descriptionCode}`, value: {id : user.id} }))
        );
      }
    }
  });
};