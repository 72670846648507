import React, { useState } from "react";
import FilterBar from "./filterBar";
import MotionFilters from "./motionFilters";

const Header = ({
  search,
  setSearch,
  setFilterValue,
  filterValue,
  FilterMenu,
}) => {
  const [motionFilters, setMotionFilters] = useState(false);
  const onFilterClick = () => {
    setMotionFilters(!motionFilters);
  };

  return (
    <div>
      <FilterBar
        search={search}
        FilterMenu={FilterMenu}
        setSearch={setSearch}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
        onFilterClick={onFilterClick}
        motionFilters={motionFilters}
      />
      <MotionFilters
        motionFilters={motionFilters}
        FilterMenu={FilterMenu}
        setFilterValue={setFilterValue}
        filterValue={filterValue}
      />
    </div>
  );
};
export default Header;
