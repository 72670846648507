const appConfig = {
  // apiBaseUrl: "https://crm.smlisuzu.com/api",
  apiBaseUrl: "http://dev.smlisuzu.com:8080/api",
  smlBaseUrl: "https://smlisuzu.net",
  apiPrefix: "/api",
  authenticatedEntryPath: "app/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  enableMock: true,
};



export default appConfig;
