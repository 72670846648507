export const LOOK_UP_TYPES = {
  ALL: 0,
  DISTRICT: 1,
  STATE: 2,
  ROLE: 3,
  PERMISSION: 4,
  STATUS: 5,
  ENQUIRY_TYPE: 6,
  VEHICLE_CATEGORY: 7,
  x: 8,
  LEAD_CATEGORY: 9,
  LEAD_STATUS: 10,
  LEAD_TYPE: 11,
  USER_TYPE: 12,
  VISIT_PURPOSE: 13,
  CUSTOMER_CATEGORIES: 14,
};
